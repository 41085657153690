.about__section-img{
    border-radius: 70%;
    overflow: hidden;
    transform: skew(15deg);
    transition: var(--transition);
    width: 100%;

}
.about__section-img:nth-child(2){
    transform: skew(-15deg);
}
.about__section-img:hover{
    transform: skew(0);
}

.about__section-content h1{
    margin-bottom: 2rem;
}
.about__section-content p{
    margin-bottom: 4rem;
}

.about__story-container{
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}
.about__mission-container,
.about__vision-container{
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
}


@media screen and (max-width:1024px) {
    .about__section-img{
        width: 60%;
    }
    .about__section-content h1{
        margin-bottom: 1.2rem;
    }
    .about__story-container,.about__mission-container,.about__vision-container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .about__vision, .about__section-content{
        grid-row: 1;
    }
}

@media screen and (max-width:600px){
    .about__section-img{
        width: 80%;
        margin-inline: auto ;
    }
    .about__story-container,.about__mission-container,.about__vision-container{
       
        gap: 2rem;
    }
}