.main__header{
    width: 100vw;
    height:calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem
}
.main__header-container{
    height: 100%;
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
     
}
.main__header-left{
    margin-top: -3rem;

}
.main__header-left h4 {
    margin-bottom: 1rem;
    color: var(--color-secondary);
    font-weight: 400;
}
.main__header-left p {
    margin: 1rem 0 3.5rem 0;
    font-size: 1.1rem;
}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
}
.main__header-circle{
    width: 25rem;
    height: 25rem;
    background: linear-gradient(75deg, var(--color-secondary),transparent);
    border-radius: 50%;
    position: absolute;
}

.main__header-img{
    position: relative;
}














.values__container{
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
    
}
.values__image{
    filter: saturate(0.25);
    transition: var(--transition);
    margin-top: 1.3rem;
}
.values__image:hover{
    filter: saturate(1);
}

.valuse__right p  {
    margin: 0.5rem 0 5rem;
}

.values__wrpper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}
.card.values__value{
    padding-top: 3.5rem;
    text-align: left;
    position: relative;
}
.values__value span{
    position: absolute;
    top: -1.5rem;
}

















.faqs__wrapper{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq{
    background: var(--color-gray-500);
   
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon{
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-gray-100);
    font-size: 1.6rem;
}

.faq p{
    margin-top: 1.5rem;
    
}
















.testimoials{
    width: 50rem;
    margin-inline: auto;
}
.head  {
    margin: 0 0 4rem 25%;
}
.card .testimonial{
    position: relative;
    text-align: left;
    margin-top: 5rem;
}

 .testinonial__avatar{
    width: 4rem;
    height: 4rem;
    border: 2px solid transparent;
    border-radius: 1.5rem;
    overflow: hidden;
    position: absolute;
    margin-top: -5rem;
   left: calc(50% - 2rem);
   box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
   transition: var(--transition);
} 

.testimoials:hover .testinonial__avatar{
    border-radius: 50%;
    border-color: var(--color-gray-400) ;
}

.trstimonai__qoute{
    font-style: italic;
    margin: 1rem 0 2rem;
}

.card small .testimonial__title{
    margin-top: 0.3rem;
}
 .testimonials__btn-container{
    width: fit-content;
    margin: 2.5rem auto 0 ;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
}
.testimonials__btn{
    background: transparent;
    cursor: pointer;
    border: none;

    

}

.testimonials__btn svg{
    fill: var(--color-primary);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}














footer{
    background: var(--color-primary);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__containr{
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
    border-bottom: var(--color-gray-200);
}

.footer__containr article{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
}
.footer__containr article p {
    margin-top: 0.5rem;

}
.footer__containr article h4{
    margin-bottom: 0.6rem;
    font-size: 1rem;
}
.footer__socials{
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;

}
.footer__socials a{
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}
.footer__socials a svg{
    color: var(--color-gray-600);
    
}
.footer__socials a:hover{
    background: var(--color-gray-100);
    background-color: transparent;
}

.footer__containr article a{
    transition: var(--transition);
}


.footer__containr article a:hover{
    color: var(--color-secondary);
}

.footer__copyright{
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem;
    
}





@media screen and (max-width: 1024px){
    .main__header{
        height: fit-content;
        padding: 12rem 0;
    }
    .main__header-container{
        gap: 0;
    }
    .main__header-circle{
        width: 16rem;
        height: 16rem;
    }
 


    
    .programs.wrapper{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .values__container{
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;

    }
    .values__wrpper{
        gap: 4rem 3rem;
    }
    .values__image{
        display: none;
    }



    .faqs__wrapper{
        grid-template-columns: 1fr;
    }




    .testimoials{
        width: 75%;
    }
}


@media screen and (max-width: 600px){
    .main__header{
        margin-top: 0;
        padding: 12rem 0 0 ;
        height: 100vh;
    }
    .main__header-right{
        display: none;
    } 

    .main__header-container{
        grid-template-columns: 1fr;
    }


    .programs__wrapper{
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .programs__program{
        width: 84%;
        margin: 0 auto;
    }

    .values__wrpper{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .card.values__value{
        width: 84%;
        margin-inline: auto ;
    }

    .faqs__wrapper{
    gap: 1rem;
    margin-top: 3rem;
    }
    .testimoials{
        width: 100%;
    }

}

