.gellary__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 

}

.gellary__container article {
    border: 1rem solid transparent;
    transition: var(--transition);
    display: flex;
}


.gellary__container article:hover{
    border-color: var(--color-gray-100);
} 