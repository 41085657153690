
.contact__container{
    display: grid;
    place-items: center;

}

.contact__wrpper{
    width: fit-content;
    display: flex;
    gap: 2rem;
}

.contact__wrpper a {
    background: var(--color-primary);
    font-size: 1.8rem;
    border: 2px solid transparent;
    transition: var(--transition);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
}
.contact__wrpper a:hover{
    background: transparent;
    border-color: var(--color-gray-100);
}




@media screen and (max-width: 600px) {
    .contact__wrpper{
        gap: 1.5rem;
    }
    .contact__wrpper a {
        padding: 1rem;
        border-radius: 1rem;
        font-size: 1.2rem;
    }
}