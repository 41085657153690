.plans__container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
.plans h3{
    margin-bottom: 0.8rem
}
.plans small{
    margin-bottom: 2rem;
}
.plans h1{
    color: var(--color-primary-variant);
    display: inline;
}

.plans h2{
    display: inline;
    color: var(--color-gray-400);
}
.plans p{
    margin-top: 1rem;
    color: var(--color-gray-100);
}
.plans .disabled{
    color: var(--color-gray-400);
}
.plans button{
    margin-top: 3rem;
    cursor: pointer;
    border: none;
}

.work{
    margin: 4rem 6rem;
    width: 600px;
    background: var(--color-gray-400);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, .1);


}

.content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    
}
.work h1{
    font-size: 2rem;
    color: paleturquoise;

   
}
.warrap{
    display: flex;
    text-align: center;
    cursor: pointer;
}

.zeiad{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out;

}

.warrap:hover .zeiad  {
    transform: scale(1.1);
}

@media screen and (max-width:1024px) {
    .plans__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width:600px){
    .plans__container{
        grid-template-columns: 1fr;
    }
    .plans{
        width: 90%;
        margin-inline: auto ;
    }
}
