*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

:root{
  --primary-hue:210;
  --gray-hue: 240;
  --color-primary: hsl(var(--primary-hue), 100% , 50%);
  --conlor-primary-variant: hsl(var(--primary-hue), 100% , 65%);
  --color-secondary: #ffb116;

  --color-gray-100: hsl(var(--gray-hue),47%, 94%);
  --color-gray-200: hsl(var(--gray-hue),26%, 75%);
  --color-gray-300: hsl(var(--gray-hue),24%, 64%);
  --color-gray-400: hsl(var(--gray-hue),16%, 41%);
  --color-gray-500: hsl(var(--gray-hue),44%, 25%);
  --color-gray-600: hsl(var(--gray-hue),48%, 15%);

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 500ms ease;
}

body{
  background: var(--color-gray-600) url('./images/bg_texture.png');
  font-family: 'Montserrat', sans-serif;
  color: var(--color-gray-200);
  line-height: 1.7;
  overflow-x: hidden;
}
.container{
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1,h2,h3,h4,h5,h6{
  line-height: 1.2;
  color: var(--color-gray-100);
}

h1{
  font-size: 3rem;
}
h2{
  font-size: 2rem;
}
a{
  color: var(--color-gray-100);
}

img{
  display: block;
  object-fit: cover;
  width: 100%;
}
.btn{
  color: var(--color-gray-100);
  width: fit-content;
  margin: 0 auto;
  padding: 0.9rem 2rem;
  background-color: var(--color-primary);
  border-radius: 1.5rem;
  transition: var(--transition);
}

.btn:hover{
  background-color: var(--color-secondary);
  color: var(--color-gray-600);
}

.btn.lg{
  padding: 1.2rem 3rem;
  border-radius: 2rem;
  font-size: 1.2rem;
}

.btn.sm{
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
}
.btn.sm:hover{
  background-color: var(--color-gray-100);
}


section{
  margin-top:10rem

}

.section__head{
  display: flex;
  align-items: center;
}
.section__head span{
  padding: 0.3rem 0.8rem;
  color: var(--color-secondary);
  font-size: 1.8rem;
  margin-top: 0.6rem;
}






.card{
  background: var(--color-gray-500);
  border: 2px solid transparent;
  text-align: center;
  padding: 3rem 2rem;
  border-radius: 0 3rem 0 3rem;
  transition: var(--transition);
}

.card:hover{
  background: transparent;
  border-color: var(--color-gray-400);
  cursor: default;
}

.card span{
  width: 3rem;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-gray-100);
  font-size: 1.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto ;
  border-radius: 1rem;
  transition: var(--transition);
}

.card:hover span{
  background: var(--color-secondary);
  color: var(--color-gray-600);
}
.card small{
  margin-top: 1rem;
  display: block;
  font-weight: 300;
}




.room{
  width: 80%;
  margin: auto;
}

.programs{
  margin-top: 4rem;
  
}
.container__programs-container{
  width: 100vw;
}

.programs__wrapper{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

.programs__program a{
  margin-top: 2.5rem ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.programs__program:hover a{
  background: var(--color-secondary);
  color: var(--color-gray-600);
}












.header{
  margin-top: 5rem ;
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container{
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
  background: black;
}

.header__containr-bg{
  position: absolute;
  width: 100%;
}
.header__containr-bg img{
  opacity: 0.5;
}

.header__content{
  position: relative;
  width: 44%;
  margin: 0 auto;
  color: var(--color-gray-100);
  text-align: center;
}
.header__content p{
  color: rgba(255,255,255, 0.7);
}





@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
    
  }
  h1{
    font-size: 2rem;
  }
  h2{
    font-size: 1.6;
  }
  .header__content{
    width: 76%;
  }
}




@media screen and (max-width: 600px){
  h1{
    font-size: 2.2rem;
    line-height: 1.3;
  }

  section{
    margin-top: 7rem;
  }

  .header{
    height: fit-content;
  }
  .header__content{
    width: var(--container-width-md);
    padding: 3rem 0;
  }
  .header__content{
    font-size: 0.8rem;
  }
}











